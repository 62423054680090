$primary: #e35d6a;
$secondary: #fd9843;
$tertiary: #e35d6a;
$success: #00c462;
$dark: #314559;
$medium: #5a6a7a;
$light: #c1c7cd;
$light-30p: #f8f8f7;
$warning: #ef703e;
$danger: #d82929;
$info: #46afe3;
