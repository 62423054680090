/* useless car déjà computed au moment ou ça passe en dark ?

 :root{
  @media (prefers-color-scheme: dark) {
    --bs-white-rgb: #283144;
  }
}
  */

$bottom-most: #121212;
$bottom-layer: #1c1c1e;
$top-layer: #2c2c2e;
$topmost-layer: #3a3a3c;

body.dark-mode {
  color: white;
  --bs-body-color-rgb: 255, 255, 255;

  .bg-white {
    background-color: #283144 !important;
    color: white;
  }
  .card {
    background-color: #283144 !important;
    color: white;
  }

  .table {
    color: white;
  }
  .textassistList {
    //Voir pour mettre un bg-white dans le composant textassist
    background: #283144 !important;
    color: white;
  }

  .bg-light-30p {
    background-color: $bottom-layer !important;
  }

  aside {
    background-color: $top-layer !important;
  }

  //backoffice
  /* removed because we went for bg-light-30p
  .wrapper > div {
    background-color: #283144 !important;
  }
  
  .wavy {
    background-color: #1a3c82 !important;
  }*/

  section {
    background-color: #283144 !important;
    color: white;
  }

  .table > :not(caption) > * > * {
    background-color: inherit;
    color: white;
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: rgb(48 57 76);
    color: white;
  }

  .modal-content {
    background-color: #283144 !important;
  }

  .wrapper > div {
    background-color: #283144 !important;
  }
}
