@use 'sass:color';

@import 'animate.css/animate.min.css';

@import 'bootstrap_init';

@import 'forms';
@import 'spin';
@import 'shadows';
@import 'headers';
@import 'bootstrap_customization';
@import 'section_counter';
@import 'tables';
@import 'ic2-switch';
@import 'dark_mode';

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3);

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    //border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: grey;
  }
}

/*
html {
  height: 100%;
}
*/
body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: var(--bs-light-30p); //pour l'entete sur safari iOS
  min-height: 100%;
}

a {
  text-decoration: none;
  &[href] {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
  user-select: none;
}
